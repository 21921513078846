<template>
  <asom-card>
    <div v-if="isLoadingProfile" class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <form v-else class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
      <asom-alert class="col-span-2" v-if="error" variant="error" :error-message="error" />
      <asom-form-field
        label="Name"
        required
        :state="inputStates('name')"
        error="Name is required"
      >
        <p>{{name}}</p>
      </asom-form-field>
      <asom-form-field
        label="Email"
        required
        :state="inputStates('email')"
        error="Email is required"
      >
        <p>{{email}}</p>
      </asom-form-field>
      <asom-form-field
        label="Role"
        required
        :state="inputStates('roleId')"
        error="Role is required"
      >
        <asom-input-select
          :objectModelValue="false"
          v-model="roleId"
          :options="roleOptions" 
          placeholder="Select a role" 
          :state="inputStates('roleId')"
        />
      </asom-form-field>
      <asom-form-field
        label=" "
        description="Check if this is a senior officer"
        v-if="hasSeniorRole"
      >
        <asom-input-checkbox
          class="pt-2"
          v-model="isSenior"
          label="Is Senior Officer?"
        >
        </asom-input-checkbox>
      </asom-form-field>
      <div v-else></div>
      <div class="col-span-2 flex justify-end space-x-4">
        <asom-button text="Update Role" @click="onSubmit" :loading="isSubmitting" />
        <asom-button text="Cancel" variant="secondary" @click="$router.push('/users')" />
      </div> 
    </form>
  </asom-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from '@vuelidate/validators'
import get from 'lodash.get'

import { getUserDetails, changeUserRoles } from '../../services/user.service'
import inputStates from '@/mixins/inputStates'
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'ChangeUserRole',
  mixins: [ inputStates ],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      officerId: get(this, '$route.params.id'),
      name: '',
      email: '',
      roleId: '',
      isSenior: false,
      isLoadingProfile: false,
      isSubmitting: false,
      error: '',
    }
  },
  validations() {
    return {
      officerId: { required },
      roleId: { required },
      computedRoleId: { required },
    }
  },
  mounted() {
    if (this.v$) {
      this.v$.$reset()
    }
    this.getUserDetails()
  },
  watch: {
    roleId() {
      this.isSenior = false;
    }
  },
  computed: {
    ...mapGetters({
      roleOptions: 'adminResource/nonSeniorRoleOptions',
    }),
    hasSeniorRole() {
      return this.$store.getters['adminResource/hasSeniorRole'](this.roleId);
    },
    computedRoleId() {
      return this.$store.getters['adminResource/seniorRoleId'](this.roleId, this.isSenior);
    }
  },
  methods: {
    setUserDetails({ roles, name, email }) {
      if (name)
        this.name = name
      if (email)
        this.email = email
      if (roles && roles.length === 1) {
        const role = this.roleOptions.find(r => r.label === roles[0])
        if (role) {
          this.roleId = role.value
          return true;
        }
      }
      return false
    },
    async getUserDetails() {
      if (this.setUserDetails(get(this, '$route.params', {})))
        return;
      this.isLoadingProfile = true
      const result = await getUserDetails(this.officerId)
      if (result.success) {
        this.setUserDetails(get(result, 'payload.data', {}))
      } else {
        this.error = result.payload
      }
      this.isLoadingProfile = false
    },
    async onSubmit() {
      if (this.isSubmitting)
        return;
      this.error = ''
      this.v$.$reset()
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        const result = await changeUserRoles({
          officerId: this.officerId,
          roleIds: [ this.computedRoleId ],
        })
        this.isSubmitting = false
        if (result.success)
          this.$router.push('/users')
        else
          this.error = result.payload
      } else {
        this.error = 'Please complete all required fields'
      }
    }
  }
}
</script>
